import * as React from "react";
import { useAuthToken } from "../../hooks/useAuthToken";
import { createContext, useCallback } from "react";
import { IAuthTokenPayload } from "@coderone/library";

interface IAuthContextProps {
    readonly token: string | null;
    readonly setToken: ((value: string | null) => void) | null;
    readonly payload: IAuthTokenPayload | null;
}

export const AuthContext = createContext<IAuthContextProps>({ token: null, setToken: null, payload: null });

export const AuthContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    const { authToken, setAuthToken, tokenData } = useAuthToken();

    const onSetToken = useCallback(
        (value: string | null) => {
            setAuthToken(value);
        },
        [setAuthToken]
    );

    return <AuthContext.Provider value={{ token: authToken, setToken: onSetToken, payload: tokenData }}>{children}</AuthContext.Provider>;
};

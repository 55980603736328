import * as React from "react";
import { AppContext } from "../../AppContext/AppContext";

import "../../utilities/bootstrap-page";

const App: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    return <AppContext>{children}</AppContext>;
};

export default App;

import { IAuthTokenPayload } from "@coderone/library";
import { LocalStorageKey } from "../utilities/LocalStorageKey";
import { useLocalStorage } from "./useLocalStorage";
import { useMemo } from "react";
import { utcNow } from "../utilities/utcNow";
import jwt_decode from "jwt-decode";

interface IHookResult {
    readonly authToken: string | null;
    readonly setAuthToken: (value: string | null) => void;
    readonly tokenData: IAuthTokenPayload | null;
    readonly isValid: boolean;
}

export const useAuthToken = (): IHookResult => {
    const [authToken, setAuthToken] = useLocalStorage(LocalStorageKey.AuthToken, null);
    const tokenData = useMemo(() => {
        if (authToken !== null) {
            return jwt_decode<IAuthTokenPayload>(authToken);
        }
        return null;
    }, [authToken]);
    if (tokenData !== null) {
        if ((tokenData?.exp || 0) < utcNow()) {
            setAuthToken(null);
        }
    }
    const isValid = authToken !== null;
    return { authToken, setAuthToken, tokenData, isValid };
};

import { timeFromNow } from "./timeFromNow";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../locales/en.json";

declare module "i18next" {
    interface CustomTypeOptions {
        returnNull: false;
    }
}

const resources = {
    en: {
        translation: en,
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: "en",
    fallbackLng: ["en"],

    keySeparator: ".",

    interpolation: {
        escapeValue: false,
    },
});
i18n.services.formatter?.add("fromNow", (value, _lng, _options) => {
    const date = new Date(value);
    return timeFromNow(date);
});

export default i18n;

import { useState } from "react";
import { LocalStorageKey } from "../utilities/LocalStorageKey";

export const useLocalStorage = (key: LocalStorageKey, initialValue: string | null): [string | null, (value: string | null) => void] => {
    const [storedValue, setStoredValue] = useState<string | null>(() => {
        try {
            if (typeof window === "undefined") {
                return initialValue;
            }
            const item = window.localStorage.getItem(key);
            return item !== null ? item : initialValue;
        } catch (error) {
            console.error(error);
            return initialValue;
        }
    });

    const setValue = (value: string | null) => {
        try {
            setStoredValue(value);
            if (typeof window === "undefined") {
                return;
            }
            if (value === null) {
                window.localStorage.removeItem(key);
            } else {
                window.localStorage.setItem(key, value);
            }
        } catch (error) {
            // TODO: log to telemetry
            console.error(error);
        }
    };

    return [storedValue, setValue];
};

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-adjudicated-games-tsx": () => import("./../../../src/pages/adjudicated-games.tsx" /* webpackChunkName: "component---src-pages-adjudicated-games-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-bomberland-tsx": () => import("./../../../src/pages/bomberland.tsx" /* webpackChunkName: "component---src-pages-bomberland-tsx" */),
  "component---src-pages-build-info-tsx": () => import("./../../../src/pages/build-info.tsx" /* webpackChunkName: "component---src-pages-build-info-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-docs-tsx": () => import("./../../../src/pages/docs.tsx" /* webpackChunkName: "component---src-pages-docs-tsx" */),
  "component---src-pages-game-tsx": () => import("./../../../src/pages/game.tsx" /* webpackChunkName: "component---src-pages-game-tsx" */),
  "component---src-pages-history-tsx": () => import("./../../../src/pages/history.tsx" /* webpackChunkName: "component---src-pages-history-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-job-queue-tsx": () => import("./../../../src/pages/job-queue.tsx" /* webpackChunkName: "component---src-pages-job-queue-tsx" */),
  "component---src-pages-leaderboard-tsx": () => import("./../../../src/pages/leaderboard.tsx" /* webpackChunkName: "component---src-pages-leaderboard-tsx" */),
  "component---src-pages-leaderboard-v-3-tsx": () => import("./../../../src/pages/leaderboard-v3.tsx" /* webpackChunkName: "component---src-pages-leaderboard-v-3-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-pricing-subscription-tsx": () => import("./../../../src/pages/pricing-subscription.tsx" /* webpackChunkName: "component---src-pages-pricing-subscription-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-redeem-ticket-tsx": () => import("./../../../src/pages/redeem-ticket.tsx" /* webpackChunkName: "component---src-pages-redeem-ticket-tsx" */),
  "component---src-pages-replays-tsx": () => import("./../../../src/pages/replays.tsx" /* webpackChunkName: "component---src-pages-replays-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */),
  "component---src-pages-submissions-submission-id-tsx": () => import("./../../../src/pages/submissions/[submissionId].tsx" /* webpackChunkName: "component---src-pages-submissions-submission-id-tsx" */),
  "component---src-pages-submissions-tsx": () => import("./../../../src/pages/submissions.tsx" /* webpackChunkName: "component---src-pages-submissions-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-users-[username]-tsx": () => import("./../../../src/pages/users/[username].tsx" /* webpackChunkName: "component---src-pages-users-[username]-tsx" */),
  "component---src-pages-users-tsx": () => import("./../../../src/pages/users.tsx" /* webpackChunkName: "component---src-pages-users-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blog.template.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-documentation-template-tsx": () => import("./../../../src/templates/documentation.template.tsx" /* webpackChunkName: "component---src-templates-documentation-template-tsx" */)
}


import { createTheme, initializeIcons, loadTheme } from "@fluentui/react";
import { Font } from "./Constants/Font";
import "./i18n";
initializeIcons(undefined, { disableWarnings: true });

/*  TEMP: sets default font as Inter for Fluent UI components
    TODO: replace with custom styled-components instead    */

loadTheme(
    createTheme({
        defaultFontStyle: { fontFamily: Font.Inter, fontWeight: "400" },
        fonts: {
            medium: { fontSize: 14 },
        },
    })
);

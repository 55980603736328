import "@fontsource/inter/variable-full.css";
import "@fontsource/space-grotesk/variable.css";
import "@fontsource/ubuntu-mono";
import * as React from "react";
import App from "./src/components/App/App";
import { GlobalStyle } from "./src/utilities/GlobalStyles";
// Fontsource font-variants: https://fontsource.org/docs/variable-fonts

export const wrapPageElement = ({ element, props }) => {
    return (
        <React.Fragment>
            <GlobalStyle />
            <App {...props}>{element}</App>
        </React.Fragment>
    );
};

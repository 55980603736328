export abstract class Palette {
    public static readonly Primary100 = "rgba(0, 112, 244, 1)";
    public static readonly Primary80 = "#338DF6";
    public static readonly Primary50 = "rgba(0, 112, 244, 0.5)";
    public static readonly Primary10 = "#E6F1FE";
    public static readonly Primary110 = "#0064DA";
    public static readonly Accent100 = "rgba(185, 76, 187, 1)";
    public static readonly Accent10 = "rgba(185, 76, 187, 0.1)";
    public static readonly Black10 = "rgba(0, 0, 0, 0.1)";
    public static readonly Black100 = "#000000";
    public static readonly Neutral100 = "rgba(0, 0, 16, 1)";
    public static readonly Neutral90 = "#35363A";
    public static readonly Neutral60 = "#52555E";
    public static readonly Neutral50 = "#767B87";
    public static readonly Neutral40 = "#AFB4C5";
    public static readonly Neutral30 = "#C4C9D9";
    public static readonly Neutral20 = "#E7EAF4";
    public static readonly Neutral10 = "#F5F6FB";
    public static readonly Neutral0 = "#FFFFFF";
    public static readonly White100 = "rgba(255, 255, 255, 1)";
    public static readonly White80 = "rgba(255, 255, 255, 0.8)";
    public static readonly White50 = "rgba(255, 255, 255, 0.5)";
    public static readonly White20 = "rgba(255, 255, 255, 0.2)";
    public static readonly White10 = "rgba(255, 255, 255, 0.1)";
    public static readonly Success100 = "#2FB344";
    public static readonly Success10 = "#EBF8ED";
    public static readonly Error100 = "#D63939";
    public static readonly Error10 = "#FBECEC";
}
